@charset "UTF-8";

@font-face {
  font-family: "sistema-politico";
  src:url("fonts/sistema-politico.eot");
  src:url("fonts/sistema-politico.eot?#iefix") format("embedded-opentype"),
    url("fonts/sistema-politico.woff") format("woff"),
    url("fonts/sistema-politico.ttf") format("truetype"),
    url("fonts/sistema-politico.svg#sistema-politico") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "sistema-politico" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sistema-politico" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bell:before {
  content: "\61";
}
.icon-calendar:before {
  content: "\62";
}
.icon-close:before {
  content: "\63";
}
.icon-upload:before {
  content: "\64";
}
.icon-reorder-arrow:before {
  content: "\65";
}
.icon-config:before {
  content: "\66";
}
.icon-filtros:before {
  content: "\67";
}
.icon-diario:before {
  content: "\68";
}
.icon-orgaos:before {
  content: "\69";
}
.icon-tipo-diario:before {
  content: "\6a";
}
.icon-tipo-materia:before {
  content: "\6b";
}
.icon-imprimir:before {
  content: "\6c";
}
.icon-limpar:before {
  content: "\6d";
}
.icon-sort-arrow:before {
  content: "\6e";
}
.icon-seta-esquerda:before {
  content: "\6f";
}
.icon-seta-direita:before {
  content: "\70";
}
.icon-seta-calendar:before {
  content: "\71";
}
.icon-save:before {
  content: "\72";
}
.icon-reticencias:before {
  content: "\73";
}
.icon-power-off:before {
  content: "\74";
}
.icon-plus:before {
  content: "\75";
}
.icon-voltar-materia:before {
  content: "\76";
}
.icon-voltar:before {
  content: "\77";
}
.icon-pdf-file:before {
  content: "\78";
}
.icon-minus:before {
  content: "\79";
}
.icon-lupa:before {
  content: "\7a";
}
.icon-user:before {
  content: "\41";
}
.icon-menu:before {
  content: "\42";
}
.icon-materias:before {
  content: "\43";
}
.icon-trash:before {
  content: "\44";
}
.icon-sumario:before {
  content: "\45";
}
.icon-lista:before {
  content: "\46";
}
.icon-mail-canal:before {
  content: "\48";
}
.icon-website:before {
  content: "\49";
}
.icon-social:before {
  content: "\4a";
}
.icon-user-canal:before {
  content: "\4b";
}
.icon-telefone:before {
  content: "\4c";
}
.icon-whatsapp:before {
  content: "\4d";
}
.icon-nucleo-logo:before {
  content: "\4e";
}
.icon-agenda:before {
  content: "\4f";
}
.icon-home:before {
  content: "\50";
}
.icon-atendimento:before {
  content: "\51";
}
.icon-filtros-input:before {
  content: "\52";
}
.icon-close-tags:before {
  content: "\53";
}
.icon-arrow-paths:before {
  content: "\54";
}
.icon-geolocation:before {
  content: "\55";
}
